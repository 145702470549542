<template>
    <div>
        <div class="wenda-search">
            <index-search></index-search>
        </div>
        <div class="base-width" style="padding-left: 10px;padding-right: 10px">
            <el-row :gutter="20">
                <el-col :span="17" :offset="1">
                    <div class="box-s">
                        <router-view></router-view>
                    </div>
                </el-col>
                <el-col :span="5">
                    <div class="right-recommend">
                        <right-side class="box-s"></right-side>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import IndexSearch from "@/components/index/top/index-search.vue";
import RightSide from "@/components/wenda/right/right-side.vue";
export default {
    name: "index",
    components: {
        RightSide,IndexSearch
    },
    mounted() {

    },
    created() {

    },
    data: function () {
        // let status = this.$route.meta.type || 'index';
        // status = status === 'index' ? '' : (status === 'done' ? 100 : 1);
        return {
            query: {
            }
        }
    },
    methods: {}
}

</script>
<style scoped lang="less">
.box-s {
    box-shadow: 0 2px 2px 0 rgb(204 204 204 /50%);
}

.wenda-search {
    //height: 350px;
    display: flex;
    align-items: center;
    //background-color: #fff;
    justify-content: center;
    height: 100px;
    //padding: 25px 0;
    margin: 10px;
    //padding: 15px;
    //background: url("/static/bbs/images/menu/top-bg.png") top center no-repeat;
    //background-size: cover;
    //display: flex;
    //align-items: center;
    //color: #eee;

}

.bbs-content {
    border-radius: 4px;
    padding: 20px;
    background-color: #fff;
}
</style>
