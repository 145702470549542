<template>
    <div v-if="show" class="connected-answer">
        <div class="title">相关内容</div>
        <div class="body">
            <div  v-for="(item,index) in list" :key="item.answer_id" class="item">
                <div class="index bbs-third-color" :class="['index'+(Math.floor(index/3))]">{{ index + 1 }}</div>
                <a target="_blank" :href="'/wenda/detail/'+item.answer_id+'.html'" class="answer-title bbs-primary-color">
                    {{ item.title }}
                </a>
            </div>
            <div class="no-data" v-if="list.length===0">
                    这个问题暂无相关内容
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            baseUrl: '/bbs/answer/connected',
            list: [],
            show:/\/wenda\/detail\/(\d+)\.html/.test(this.$route.path)
        }
    },
    mounted() {
        if (this.show){
            this.getConnected();
        }
    },
    methods: {
        getConnected() {
            this.$bbs.get(this.baseUrl,{
                params:{
                    answer_id:this.getAnswerId(),
                }
            }).then(data => {
                this.list = data.data.filter(v=>{
                    return v.answer_id!=this.getAnswerId();
                });
                //这里更新相关问题的链接
                this.$store.commit('updateConnect',this.list);
            });
        },
        getAnswerId() {
            let match=this.$route.path.match(/\/wenda\/detail\/(\d+)\.html/);
            if (match&&match[1]);
            return match[1];
        }
    }
}
</script>
<style scoped lang="less">
.connected-answer {
    margin-top: 15px;
    padding: 15px;
    background-color: #fff;

    .body {
        .item {
            display: flex;
            padding: 6px 0;
            line-height: 20px;
            .index {
                width: 30px;
                text-align: center;
                flex-shrink: 0;

                &.index0 {
                    color: #fc5d5d;
                }
            }

            .answer-title {

                display: block;
                flex: 1;
                word-break: break-word;
                cursor: pointer;

                &:hover{
                    text-decoration: none;
                }
            }
        }
        .no-data{
            color: #666;
            padding: 10px;
        }
    }
}
</style>
